.Pitch {
    border-radius: 50%;
    /* border: 1px solid black; */
    background-color: blue;
    width: 20px;
    height: 20px;
    position: absolute;
    text-align: center;
    line-height: 15px;
    color: black;
    font-size: 0.6em;
    margin-top: -10px;
    margin-left: -10px;
    border:1px solid black;
}