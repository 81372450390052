.Trainings{
    width:80%;
    /* border:1px solid #eee;
    box-shadow:0 2px 3px #ccc; */
    padding:10px;
    margin:10px auto;
    box-sizing:border-box;
    text-align:center;
}

#root > main > div > div.ag-theme-balham-dark > div > div > div.ag-root-wrapper-body.ag-layout-normal > div > div.ag-header.ag-pivot-off > div.ag-header-viewport > div > div > div:nth-child(1) > div.ag-cell-label-container.ag-header-cell-sorted-none > div {
    justify-content: center!important;
 }

@media (min-width:600px) {
    .Trainings{
        width:80%;
        /* border:1px solid #eee;
        box-shadow:0 2px 3px #ccc; */
        padding:10px;
        margin:10px auto;
        box-sizing:border-box;
        text-align:center;
    }
}