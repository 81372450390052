.Toolbar{
    height:46px;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    background-color:#000;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
    margin-left:0px;
    box-sizing: border-box;
    z-index:90;
    border-bottom:2px solid white;
}

.Title{
    color: #FFC000;
    width: 300px;
    font-size: 34px;
    font-weight: bold;
    padding-left: 10px;
}

.Toolbar nav{
    height:100%;
    width:70%;
}

.Logo{
    height: 80%;
}

.Logo img{
    height:65px;
}

@media (max-width:1079px){
    .DesktopOnly{
        display:none;
    }
    .Logo img{
        display:none;
    }
}

@media (min-width:800px){
    .MobileOnly{
        display:none;
    }


}
