.PitchList{
    width: 500px;
    vertical-align: top;
    margin-top:10px;
    margin-left:auto;
    margin-right:auto;
    font-size:12px;
    display:inline-block;
}
.PopContainer{
    display:inline-block;
    vertical-align: -12px;
}



.Strike{
    background-color:red;
}

.Ball{
    background-color:green;
}