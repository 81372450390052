.MainContainer{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    text-align:center;
}

.Wrapper{
    width:100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}


.PitchHistory{
    width:100%;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.ButtonWrapper{
    margin-left: auto;
    margin-right: auto;

}

.TitleContainer{
    text-align:center;
}

.ForceQuitMessageArea{
    width: 97%;
    padding-right:10px;
    height: 237px;
    color:black;
    font-weight: bold;
}

.ForceQuitButton{
    width:100%;
    margin-left:auto;
    margin-right:auto;
}

.Velocity{
    background-color: black;
    width: 30px;
}

.VelocitySpan{
    margin-left: 5px;
    margin-right: 5px;
}

.BatterFilters h3{
    background-color: rgb(102, 102, 102);
    display: table;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;

}

.PlotterContainer{
    display:block;
}

.PitchPlotterContainer{
    display:inline-block;
    vertical-align: top;
}

.PitchTypeButtons{
    display:block;
    width:281px;
    margin-left:auto;
    margin-right:auto;
}
.PitchButton{
    display: inline-block;
    width: 42px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: black;
    font-weight: bold;
    background-color: #6386c9;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.InPlayButton{
    background-color: #6386c9;
}

.SMButton{
    background-color: red;

}

.TakeButton{
    background-color: green;

}



.FoulButton{
    background-color:#ffc000;
}

.SlapButton{
    background-color:#702fa0;
}
.BuntButton{
    background-color:#805f01;
}


.BattingSideButtons{
    display:block;
    width:281px;
    margin-left:auto;
    margin-right:auto;
}

.BattingSideButton{
    display: inline-block;
    width: 42px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    background-color: #444;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.BattingSideButtonActive{
    color:black;
    background-color:white;
}

.PlateAppearanceButtons{
    display: inline-block;
    width: 370px;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
}

.DeleteButton{
    margin-right: 0px;
    margin-left: auto;
    text-align: left;
    display: inline-block;
    width: 110px;
    float:right;
}

.QuitButton{
    margin-right:0px;
    margin-left:auto;
    text-align:right;
    display:inline-block;
    width: 110px;
    float:right;
}
/*
@media (min-width:600px) {
    .MainContainer{
        width: 860px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }
}*/

@media (min-width:1200px){
   
    .Wrapper{
        display:inline-block;

    }

    .PitchHistory{
        float: left;
        width: 100%;
        /* margin-top: -63px; */
        /* margin-left: 20px; */
        text-align: left;
    }
    .ButtonWrapper{
        margin-left: 50px;
        margin-right: auto;
    
    }
}