.BaseRunner{
    position: absolute;
    width: 30px;
    height: 30px;
    font-size:14px;
    text-align: center;
    line-height: 30px;
    border: 1px solid blue;
    border-radius:20px;
    cursor: pointer;
    color:black;
    font-weight:bold;
    padding: 2px;
    background-color:#59a57e;
    color:white;
}