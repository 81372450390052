.ScoutingReport{
    /*margin: 20px auto;
    width:800px;
    box-sizing:border-box;*/

    width:90%;

    padding:10px;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    margin-bottom:20px;
}

.Container{

    width: 100%;
    max-width:800px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

}
/*.Notes{
    width:600px;
    margin-left:auto;
    margin-right:auto;
    padding:10px;
    background-color:white;
    color:black;
}*/

h1{
    color: #FFC000;
}


.NotesArea{
    width: 97%;
    padding-right:10px;
    height: 237px;
    color:black;
    font-weight: bold;
}

/*
.PlayersTable{
    width:100%;
    text-align:left;
    outline:none;
    border:none;
    margin-bottom: 15px;
}

.PlayersTable td{
    border:none;
    border-bottom:1pt solid rgb(61, 60, 58);
    padding-top:5px;
    padding-bottom:5px;
}

.PlayersTable tr{
    outline: 1px solid white;
}

.PlayersTable th{
    color:#FFC000;
    font-size:16px;
    font-weight:bold;
    border:none
}


.PlayersTable .Player{
    background-color:#4c4c4c;
    color:#FFF;
    font-weight:bold;
}*/

.PlayerTable{
    /*outline: 1px solid white; */
    outline: none;
    /* border: none; */
    width:100%;
    margin-bottom: 15px;
    border-collapse: collapse;
}

.PlayerTable tr{
    outline: 1px solid white;
    outline-style: inset;
}

.PlayerTable td{
    border:none;
    border-bottom:1pt solid rgb(61, 60, 58);
    padding-top:5px;
    padding-bottom:5px;
}

.PlayerTable .Player{
    background-color:#4c4c4c;
    color:#FFF;
    font-weight:bold;
}


