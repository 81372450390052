html, body { height: 100%; }
.Content{
    margin-top:72px;
    font-family: 'Source Sans Pro', sans-serif;
    color:white;
    /*background-color:black;*/

    background-image: url('../../assets/diamond-full.png');
    background-repeat:no-repeat;
    background-position:center;
    background-attachment: fixed;
    min-height:708px;
    
}


.Content:before{
    /* content:' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
    background-image: url('../../assets/logo.jpg');
    background-repeat:no-repeat;
    background-position:center;
    background-attachment: fixed; */
    filter: grayscale(100%);
}

.DatagridContainer{
    color:black;
}