.GameState{
    margin: 0px auto;
    width:80%;
    text-align:center;
    /*box-shadow:0 2px 3px #ccc;
    border: 1px solid #eee;*/
    padding:0px;
    box-sizing:border-box;
    position:relative;
    display:none;
}

.SelectPlayer{
    text-decoration:underline;
    cursor:pointer;
}
.SelectPlayer:hover{
    cursor:pointer;
}

.InningSpan{
    font-size:40px;
    margin-right:20px;
}

.QuitButton{
    margin-right:0px;
    margin-left:auto;
    text-align:right;
    display:block;
    position: absolute;
    width: 160px;
    left: 80%;
    top: -45px;
}

.DeleteButton{
    margin-right:0px;
    margin-left:auto;
    text-align:right;
    display:block;
    position: absolute;
    width: 160px;
    left: 90%;
    top: -45px;
}

.SpanContainer{
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.BallStrikeSpan{
    font-size:40px;
    margin-right:20px;
}
.OutSpan{
    font-size:40px;
    color:red;
}
.ArrowUp{
    /* border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); */
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  display:inline-block;
}

.ArrowDown{
    /* border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
  -webkit-transform: rotate(45deg); */
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid white;
  display:inline-block;
}

@media (min-width:980px){
    .GameState{
        display:block;
    }
}
@media (max-width:800px){
   
    .GameState{
        width:700px;
        /*display:inline-block;
        vertical-align: 78px;
        margin-right:100px;*/
    }

    .Button{
        font-size:10px;
        padding:0;
        margin:0;
    }
}