.PitchResultButton{
    display: inline-block;
    width: 50px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid black;
    font-weight: bold;
    background: grey;
    color: white;
    cursor: pointer;
    margin-left: 2px;
}