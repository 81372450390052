.NavigationItem{
    margin:10px 0;
    box-sizing:border-box;
    display:block;
    width:100px;
}

.NavigationItem a{
    color:white;
    text-decoration:none;
    width:100%;
    box-sizing: border-box;
    display:block;
    cursor:pointer;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{
    color:white;
    border-right:4px solid rgb(81, 84, 87);
}

@media (min-width:500px){
    .NavigationItem{
        margin:0;
        display:flex;
        height:100%;
        align-items:center;
    }
    
    .NavigationItem a{
        color:white;
        height:100%;
        padding: 12px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active{
        /* background-color:rgb(233, 125, 125); */
        background-color:rgb(201,201,201);
        border-bottom: 4px solid rgb(81, 84, 87);
        border-right:none;
        color:black;
    }
}