.Input{
    width:100%;
    padding:4px;
    box-sizing:border-box;
    color:black;
}

.InlineInput{
    width:100%;
    padding:4px;
    box-sizing:border-box;
    color:black;
}


.InlineInput .Label{
    width:30%;
    display:inline-block;
    text-align:left;
    float:left;
}

.InlineInput .InputElement{
    width:60%;
    display:inline-block;
}

.Label{
    font-weight:bold;
    display:block;
    margin-bottom: 8px;
    color:white;
}

.InputElement{
    outline:none;
    border:1px solid #ccc;
    background-color:white;
    font: inherit;
    padding: 6px 10px;
    display:block;
    width:100%;
    box-sizing:border-box;
    color:black;
}

.react-datepicker__input-container input{
    text-align:center;
}
.react-datepicker-wrapper > div > input{
    text-align:center;
}

.InputElement:focus{
    outline:none;
    background-color:#ccc;
}

.Invalid{
    border: 1px solid red;
    background-color: #FDA49A;
}
div.Select-input > input
{
    width:100px!important;
}

