.ScoutingReportSummary{
    width:90%;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    margin-bottom:20px;
    padding:10px;
}

h1{
    color: #FFC000;
}


.Notes{
    width:600px;
    margin-left:auto;
    margin-right:auto;
    padding:10px;
    background-color:white;
    color:black;
}


.NotesArea{
    width: 97%;
    padding-right:10px;
    height: 237px;
    color:black;
    font-weight: bold;
}

.ReportContainer{
    width:100%;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
    text-align:left;
}

.PlayerTable{
    width:100%;
    text-align:left;
    
}

.PlayerTable{
    /*outline: 1px solid white; */
    outline: none;
    /* border: none; */
    margin-bottom: 15px;
    border-collapse: collapse;
}

.PlayerTable tr{
    outline: 1px solid white;
    outline-style: inset;
}

.PlayerTable td{
    border:none;
    border-bottom:1pt solid rgb(61, 60, 58);
    padding-top:5px;
    padding-bottom:5px;
}

.PlayerTable .Player{
    background-color:#4c4c4c;
    color:#FFF;
    font-weight:bold;
}