.Lineup{
    margin-left: auto;
    margin-right: auto;
    display: block;
    /* width: 400px; */
    vertical-align: top;
    text-align: center;
    box-sizing: border-box;
}

.Lineup h3{
    margin:0px;
}

.LineupPlayer{
    cursor:pointer;
}

.BaseRunner{

    background-color:#59a57e;
    cursor:pointer;
}

.BaseRunner:hover{
    background-color:#59a57e;
}

.Current{
    background-color:#6a9ed9;
    color:white;
    cursor:pointer;
}

@media (max-width:600px){
   
    .Lineup{
        margin:0px;
        width:100%;
        box-shadow:none;
        border:none;
    }
}

