
.BattingSide{
    /*width: 183px;*/
    width:63px;
    margin-top: 2px;
    margin-bottom:2px;
    display:inline-block;
    float:left;
}

.SideWrapper{
    display:inline-block;
}

.SideWrapperRight{
    display:inline-block;
    margin-right:180px;
}

.SideWrapper span{
    margin-left:5px;
}

.SideWrapperRight span{
    margin-left:5px;
}

.Title{
    font-size:20px;
    font-weight:bold;
}


