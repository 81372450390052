.PitcherStats{
    display:inline-block;
   

    margin-top:10px;
}
.BatterStats{
    display:inline-block;
   
}
.PlayerLink{
    text-decoration:underline;
    font-weight:bold;
    color:#6a9ed9;
}

.Columns{
    font-weight:bolder;
}

