.PlayerPosition{
    margin: 10px auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 0px;
    box-sizing: border-box;
}

.PlayerPosition h4{
    margin:0px;
}



@media (min-width:600px) {
    .PlayerPosition{
        width:470px;
    }
}