.HitPowerButton{
    display: inline-block;
    width: 90px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid white;
    font-weight:bold;
    background:grey;
    color: white;
    cursor: pointer;
    font-size: 1.0em;
}

.HitPowerButton:hover{
    background-color: white;
    color: black;
    font-weight: bold;
}