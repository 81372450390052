.RosterStats{
    display:block;
}

.RosterStats .RosterTable{
    outline: 1px solid white;
    margin-bottom: 15px;
}

.RosterTable tr{
    border:none;
}

.RosterTable td{
    border:none;
}

/*.RosterTable tr:nth-child(odd){
    background: #333
}*/
