.Team{
    margin: 20px auto;
    width:80%;
    text-align:center;
    box-sizing:border-box;
}

.Error{
    color:red;
}
.Success{
    color:green;
}

.NotesArea{
    width: 97%;
    padding-right:10px;
    height: 237px;
    color:black;
    font-weight: bold;
}


@media (min-width:600px) {
   
}