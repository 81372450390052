.PlayerAnalysis{
    width:100%;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
}

.ForceQuitMessageArea{
    width: 97%;
    padding-right:10px;
    height: 237px;
    color:black;
    font-weight: bold;
}

.BatterAnalysis h3{
    background-color: #666;
    display: table;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
}

.PitcherAnalysis h3{
    background-color: #666;
    display: table;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
}

.PitchTypeButtons{
    display:block;
    width:281px;
    margin-left:auto;
    margin-right:auto;
}
.PitchButton{
    display: inline-block;
    width: 42px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: black;
    font-weight: bold;
    background-color: #6386c9;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.InPlayButton{
    background-color: #6386c9;
}

.SMButton{
    background-color: red;

}

.TakeButton{
    background-color: green;

}



.FoulButton{
    background-color:#ffc000;
}

.SlapButton{
    background-color:#702fa0;
}
.BuntButton{
    background-color:#805f01;
}

.PlotterContainer{
    display:block;
}

.PitchPlotterContainer{
    display:inline-block;
}

.BattingSideButtons{
    display:block;
    width:281px;
    margin-left:auto;
    margin-right:auto;
}

.BattingSideButton{
    display: inline-block;
    width: 42px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    background-color: #444;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.BattingSideButtonActive{
    color:black;
    background-color:white;
}




.PlateAppearanceButtons{
    display:block;
    width:281px;
    margin-left:auto;
    margin-right:auto;
}

.PlateAppearanceButton{
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 14px;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    background-color: #444;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
    vertical-align:top;
}

.PlateAppearanceButtonActive{
    color:black;
    background-color:white;
}