.FieldLocation{
    position: absolute;
    width: 20px;
    height: 20px;
    font-size:10px;
    text-align: center;
    line-height: 20px;
    border: 1px solid blue;
    border-radius:20px;
    cursor: pointer;
    color:black;
    font-weight:bold;
    padding: 2px;
    background-color:#6a9ed9;
}