.BaseRunners{
    /* border: 1px solid black; */
    width: 280px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-image: url(../../assets/diamond.png);
    /* background-color:black; */
    color:white;
    background-repeat:no-repeat;
    background-position-y: 0px;
    text-align:center;
}

.FirstToSecond{
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  cursor:pointer;
  display:inline-block;
  transform: translateY(112px) translateX(18px) rotate(-42deg);
  position:absolute;
}


.SecondToFirst{

    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    cursor:pointer;
    display:inline-block;
    transform: translateY(117px) translateX(16px) rotate(137deg);
    position:absolute;
}

.SecondToThird{
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    cursor:pointer;
    display:inline-block;
    transform: translateY(117px) translateX(-42px) rotate(-137deg);
    position:absolute;
  }

  .ThirdToSecond{
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    cursor:pointer;
    display:inline-block;
    transform: translateY(112px) translateX(-42px) rotate(42deg);
    position:absolute;
  }  

@media (min-width:1200px){
   
    .BaseRunners{
        display:inline-block;
        margin:8px 40px;
    }
}