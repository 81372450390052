.HitResultButton{
    display: inline-block;
    width: 45px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid white;
    color: white;
    font-weight:bold;
    background:grey;
    cursor: pointer;
    font-size: 1.0em;
    margin-left:3px;
    margin-bottom: 3px;
}

.HitResultButton:hover{
    background-color: white;
    color: black;
    font-weight: bold;
}