.NavigationItems{
    margin: 0;
    padding: 0;
    list-style:none;
    display:flex;
    flex-flow:column;
    align-items:center;
    height:100%;
    
}

@media (min-width:1080px){
    .NavigationItems{
        flex-flow:row;
        float:right;
    }
}