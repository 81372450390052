.InningScoreTable{
    width:80%;
    margin-left:auto;
    margin-right:auto;
    position:relative;
    display:block;
}

table{
    border-collapse: collapse;
    margin-left:auto;
    margin-right:auto;
}

table thead{
    font-weight:bolder;
}

table, th, td {
    border: 1px solid white;
    min-width:30px;
    padding: 0px 5px;
}

.Selected{
    /* background-color:yellow; */
}