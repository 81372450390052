.FilterTables{
    display:block;
    margin:10px;
}

.HitPowerTable{
    width: 200px;
    display: inline-block;
    border: none;
}

.HitTypeTable{
    width: 200px;
    display: inline-block;
    border: none;
}

.HitResultTable{
    width: 200px;
    display: inline-block;
    border: none;
}
