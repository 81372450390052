.PitchButton{
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    background: grey;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.PitchTypeBaseballButton{
    display: inline-block;
    width: 52px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid white;
    color: white;
    font-weight: bold;
    background: grey;
    cursor: pointer;
    font-size: 12px;
    margin-left: 2px;
}

.PitchButton:hover{
    background-color: white;
    color: black;
    font-weight: bold;
}