.LineupContainer{
    width:100%;
    position:relative;
    display:block;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
}

.LineupSelector{
    width:50%;
    /*border:1px solid #eee;
    box-shadow:0 2px 3px #ccc;*/
    padding:2px 20px;
    margin:10px auto;
    box-sizing:border-box;
    text-align:left;
    position:relative;
    display:inline-block;
    font-size: 14px;
    display: inline-block;
}
.Lineup{
    display:inline-block;
    width:50%;
    /*border:1px solid #eee;
    box-shadow:0 2px 3px #ccc;*/
    padding:2px 20px;
    margin:10px auto;
    box-sizing:border-box;
    text-align:left;
    position:relative;
    display:inline-block;
    vertical-align:top;

}

.Lineup span{
    font-size:10px;
    font-size: 10px;
    display: inline-block;
    width: 50%;

}

.LineupSelector span{
    font-size: 10px;
    display: inline-block;
    width: 50%;
}

.DatagridContainer{
    color:black;
    width:340px!important;
    display:inline-block!important;
}
.DatagridContainer h3{
    color:white;
}


 .DatagridContainer :global(.react-grid-Header) {
    width:340px!important;
} 

.DatagridContainer :global(.react-grid-HeaderRow) {
    overflow-y:hidden;
    width:340px!important;
    
}

/* .DatagridContainer :global(.react-grid-HeaderCell) {
    overflow-y:hidden;
    width:25%!important;
} */



.DatagridContainer :global(.react-grid-Canvas) {
    overflow-x: hidden!important;
}
 .DatagridContainer :global(.react-grid-Container) {
    width:340px!important;
} 

.DatagridContainer :global(.react-grid-Cell:focus) {
    outline: none;
 }

@media (min-width:800px){
    .LineupContainer{
        width:80%;
        position:relative;
        display:block;
        margin-left:auto;
        margin-right:auto;
        text-align:center;
    }
    
    .LineupSelector{
        width:50%;
        
        /*border:1px solid #eee;
        box-shadow:0 2px 3px #ccc;*/
        padding:2px 20px;
        margin:10px auto;
        box-sizing:border-box;
        text-align:left;
        position:relative;
        display:inline-block;
    }
    
    .Lineup{
        display:inline-block;
        width:50%;
        /*border:1px solid #eee;
        box-shadow:0 2px 3px #ccc;*/
        padding:2px 20px;
        margin:10px auto;
        box-sizing:border-box;
        text-align:left;
        position:relative;
        display:inline-block;
        vertical-align:top;
    
    }

    .Lineup span{
        font-size:inherit;
        display: inline-block;
        width: 50%;
    
    }

    .LineupSelector span{
        font-size: inherit;
        

    }
    
    .Player{
        display:block;
       
    }
    
    .Player span{
        display:inline-block;
        /* width:100px; */
    }
}