.Admin{
    margin: 20px auto;
    width:80%;
    text-align:center;
    /* box-shadow:0 2px 3px #ccc; */
    /* border: 1px solid #eee; */
    padding:10px;
    box-sizing:border-box;
}

.Logo{
    width:100%;
    max-width:630px;
    margin-left:auto;
    margin-right:auto;
}

.Logo img{
    width:100%;
}

