.MobileGameState{

    display:none;
}


@media (max-width:980px){
    .MobileGameState{
        width:100%;
        display:block;
    }

    /* flex container
    can have properties:
    flex-flow
    justify-content
    align-content
    align-items
     */
    .GameHeaderContainer{
        width:100%;
        margin-left:auto;
        margin-right:auto;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;   
        align-items:center;
        justify-content:center;
    }

    .HomeTeam{
        /* width:100px; */
        font-weight:bold;
        font-size:20px;
        /* margin:20px; */
    }
    .AwayTeam{
        /* width: 100px; */
        font-weight: bold;
        font-size: 0px 20px;
        margin-right: 20px;
        text-align: right;
    }

    .Score{
        font-size:50px;
        font-weight:bold;
        margin:0px 20px;
    }

    .InningOuts{
        display:block;
        text-align:center;
        /* margin:20px; */
    }

    .Inning{
        color:red;
        font-weight:bold;

    }
    .Outs{
        color:white;
        font-weight:bold;
    }

    .GameStatsContainer{
        width:100%;
        margin-left:auto;
        margin-right:auto;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;   
        align-items:center;
        justify-content:center;
    }

    .GameStatsContainer .Balls{
        color:white;
        font-weight:bold;
    }
    .GameStatsContainer .Balls span{
        margin:0px 5px;
    }
    .GameStatsContainer .Balls .Ball{
        height: 20px;
        width: 20px;
        /* background-color: #bbb; */
        border-radius: 50%;
        display: inline-block;
        vertical-align:bottom;
        border:1px solid white;
        margin:0px 2px;
        
    }
    .GameStatsContainer .Balls .Active{
        background-color: red;
    }

    .CurrentPlayersHandlers{
        width:100%;
        margin-left:auto;
        margin-right:auto;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;   
        align-items:center;
        justify-content:center;
    }

    .CurrentPlayersHandlers .CurrentPlayer{
        display:block;
    }
    .CurrentPlayersHandlers .CurrentPlayer span{
        font-size:20px;
        display:inline-block;
        margin:5px 50px;
        text-decoration:underline;
    }





    /* .item1{
        background:orange;
        color:white;
        padding:10px;
        border:5px solid black;

    }

    .item2{
        background:orange;
        color:white;
        padding:10px;
        border:5px solid black;
        width:250px;
    } */



    /*flex children
    properties:
    order
    flex
    align-self

    */
}