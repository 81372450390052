

.ResetPassword{
    margin: 20px auto;
    width:100%;
    text-align:center;
    /*box-shadow:0 2px 3px #ccc;
    border: 1px solid #eee;*/
    padding:10px;
   
}

.Error{
    color:red;
}

.Logo{
    width:630px;
    margin-left:auto;
    margin-right:auto;
}

.Auth h1{
    font-size:65px;
    color:#FFC000;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Auth h2{
    font-size:45px;
    color:#FFC000;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Auth h3{
    font-size:22px;

}


@media (min-width:800px) {
    .ResetPassword{
        width:500px;
    }
}

@media (max-width: 800px) {

}