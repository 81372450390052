.Background{
    background-image: url('../../../assets/grass-small.jpg');
    background-repeat: repeat;
    position: absolute;
    top: 9px;
    /* bottom: 0; */
    right: 0;
    left: 0;
    min-height: 900px;
    overflow:hidden;
}
.Dashboard{
    margin: 20px auto;
    width:80%;
    text-align:center;
    /* box-shadow:0 2px 3px #ccc; */
    /* border: 1px solid #eee; */
    padding:10px;
    box-sizing:border-box;
    background-image: url('../../../assets/diamond-full.png');
    background-repeat:no-repeat;
    background-position:center;
    background-attachment: fixed;
    min-height:800px;
}

.Logo{
    width:100%;
    max-width:630px;
    margin-left:auto;
    margin-right:auto;
}

.Logo img{
    width:100%;
}

