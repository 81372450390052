.FieldPlotter{
    /* border: 1px solid black; */
    width: 280px;
    height: 280px;
    margin-left: 70px;
    margin-right: auto;
    position: relative;
    background-image: url(../../../assets/diamond.png);
    /* background-color:black; */
    color:white;
    background-repeat:no-repeat;
    display:inline-block;
}