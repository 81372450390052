.Background{
    background-image: url('../../assets/grass-small.jpg');
    background-repeat: repeat;
    position: absolute;
    top: 9px;
    /* bottom: 0; */
    right: 0;
    left: 0;
    min-height: 900px;
    overflow:hidden;
}

.Diamond{
    box-sizing:border-box;
    background-image: url('../../assets/diamond-full.png');
    background-repeat:no-repeat;
    background-position:center;
    background-attachment: fixed;
    min-height:780px;
}

.Auth{
    margin: 20px auto;
    width:100%;
    text-align:center;
    /*box-shadow:0 2px 3px #ccc;
    border: 1px solid #eee;*/
    padding:10px;
   
}

.Error{
    color:red;
}

.Logo{
    width:630px;
    margin-left:auto;
    margin-right:auto;
}

.Auth h1{
    font-size:65px;
    color:#FFC000;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Auth h2{
    font-size:45px;
    color:#FFC000;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Auth h3{
    font-size:22px;

}


@media (min-width:800px) {
    .Auth{
        width:500px;
    }
}

@media (max-width: 800px) {
    .Logo{
        width:100%;
        margin-left:auto;
        margin-right:auto;
    }
    .Logo img{
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

}