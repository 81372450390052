.BatterIcon{
    position: absolute;
     background-image: url(../../../../assets/batter-view-left.png);
     transform: scaleX(-1);
     background-repeat: no-repeat;
     background-size: auto 225px;
}

.BatterIconLeft{
    position: absolute;
     background-image: url(../../../../assets/batter-view-left.png);
     
     background-repeat: no-repeat;
     background-size: auto 225px;
}


.PitcherIcon{
    position: absolute;
     background-image: url(../../../../assets/pitcher-view-left.png);
     
     background-repeat: no-repeat;
     background-size: auto 225px;
}

.PitcherIconLeft{
    position: absolute;
     background-image: url(../../../../assets/pitcher-view-left.png);
     transform: scaleX(-1);
     background-repeat: no-repeat;
     background-size: auto 225px;
}