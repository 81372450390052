.MyAccount{
    margin: 20px auto;
    width:80%;
    text-align:center;
    /*box-shadow:0 2px 3px #ccc;
    border: 1px solid #eee;*/
    padding:10px;
    box-sizing:border-box;
}

.Error{
    color:red;
}



@media (min-width:800px) {
    .MyAccount{
        width:500px;
    }
}

