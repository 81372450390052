.LineupPlayer{
    width:80%;
    border:1px solid #eee;
    box-shadow:0 2px 3px #ccc;
    padding:10px 40px;
    margin:10px auto;
    box-sizing:border-box;
    text-align:left;
    position:relative;
}

.LineupPlayer:hover{
    border: 1px solid #666;
}

.Number{
    width:50px;
    display:inline-block;
}

.ButtonSpan{
    position: absolute;
    top: 6px;
    left: 280px;
}

@media (min-width:800px) {
    .LineupPlayer{
        width: 400px;
    }
    .ButtonSpan{
        position: absolute;
        top: 6px;
        left: 400px;
    }
}

