.PitchZoneTable{
    display:block;
    width:280px;
    vertical-align: top;
    position:relative;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
}

.PitchZoneTable h3{
    background-color:grey;
    margin:0px;
    padding:5px;
}

.StrikeZone{
    position: absolute;
    width: 166px;
    height: 164px;
    outline: 1px solid black;
    top: 88px;
    left: 56px;
    box-sizing: border-box;
    pointer-events:none;
}