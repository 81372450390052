

.PitchArea{
    border: 1px solid white;
    background-color:rgb(100,100,100);
    width: 222px;
    height: 222px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;
    cursor: pointer;
    /* background-image: url(images/diamond.png); */
}

@media (min-width:500px){
   
    .PitchArea{
        transform: scale()

    }
}

