.PitchZoneArea{
    width:55px;
    height:55px;
    display:inline-block;
    outline:1px solid white;
    box-sizing:border-box;

}

.PitchZoneArea span{
    color: yellow;
    line-height: 55px;
    text-align: center;
}