.Button {
    background-color:#FFC000;
    /*PALER YELLOW background-color: #ffeebb;*/
    color:black;
    border: none;
    /*background-color: transparent;
    border: 1px solid white;
    color: white;*/
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    border-radius:20px;
    border: 3px solid #FFC000;
}
.Button:hover{
    cursor: pointer;
}
.Button:disabled{
    color:#666;
    cursor:not-allowed;
}

.Active{
    /*background-color:#aabed6;*/
    background-color:#FFC000;
    color:black;
    border: none;
    /*border: 3px solid #6a9ed9;*/
    border: 3px solid #FFC000;
}

.Small{
    font-size:12px;
    padding:5px;
    margin:5px;
}

.ExtraSmall{
    font-size: 10px;
    padding: 5px;
    margin: 5px;
}

/*
.Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
}*/

.Success {
    color: black;
}

.Danger {
    /*color: white;*/
}

.Red{
    background-color:red;
    color:white;
    border:none;
}

.Yellow{
    background-color:#FFC000;
    
    color:black;
    border: none;
}

.Grey{
    background-color:rgb(184, 182, 178);
    color:black;
    border: none;
}

.Invert{
    /*background-color:#aabed6;*/
    background-color:#FFC000;
    color:black;
    border: none;
    border: 1px solid #6a9ed9;
}

