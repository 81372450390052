.ZonesTable{
    display: inline-block;
    width: 280px;
    vertical-align: top;
}

.ZonesTable h3{
    background-color:unset !important;
    color:white;
    margin:0px;
    margin-left:auto;
    margin-right:auto;
    padding:5px;
  
}