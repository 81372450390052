.PlayerForm{
    margin: 20px auto;
    width:80%;
    text-align:center;
    box-shadow:0 2px 3px #ccc;
    border: 1px solid #eee;
    padding:10px;
    box-sizing:border-box;
    color:white;
}

.Error{
    color:red;
}
.Success{
    color:green;
}


@media (min-width:600px) {
    .PlayerForm{
        width:470px;
    }
}