.SideDrawer{
    position:fixed;
    width:280px;
    max-width:70%;
    height:100%;
    top:0;
    left:0;
    z-index:200;
    background-color:rgb(50,50,50);
    padding:32px 16px;
    box-sizing:border-box;
    transition:transform 0.3s ease-out;
}

@media (min-width:1080px){
    .SideDrawer{
        display:none;
    }
}

.Open{
    transform:translateX(0);
}

.Close{
    transform:translateX(-100%);
}

.Logo{
    height:11%;
    margin-bottom:32px;
}