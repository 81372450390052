.Team{
    width:80%;
    /* border:1px solid #eee;
    box-shadow:0 2px 3px #ccc; */
    padding:10px;
    margin:10px auto;
    box-sizing:border-box;
    text-align:center;
}

.Error{
    color:red;
}
.Success{
    color:green;
}
.NotesArea{
    width: 97%;
    padding-right:10px;
    height: 437px;
    color:black;
    font-weight: bold;
}

@media (max-width:1200px) {
    .Team{
        width:100%;
        
        /* border:1px solid #eee;
        box-shadow:0 2px 3px #ccc; */
        padding:0px;
        margin:0px auto;
        box-sizing:border-box;
        text-align:center;
    }
}